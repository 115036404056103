.kubb-pin {
  
}

.kubb-pin:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.kubb-pin input:checked + .kubb-pin__number {
  border: 2px solid #48bb78;
}

.kubb-pin__number {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 2px solid #e2e8f0;
}